import Vue from 'vue'
import Router from 'vue-router'
import AppLayout from 'bh-mod/layouts/App'
import Splash from '@/layouts/Splash'
import AuthLayout from 'bh-mod/layouts/Auth'
import store from '@/store'
import { VueAppMiddleware } from 'bh-mod'

Vue.use(Router)

const router = new Router({
	base: process.env.BASE_URL,
	mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/',
			redirect: '/v3',
			component: AppLayout,
			meta: { instanceRequired: true, auth: true, hidden: true, },
			children: [
				// Dashboards
				{
					path: '/',
					meta: {
						title: 'Email & Broadcasts',
						sidebar: true,
						dataRequired: true,
					},
					component: () =>
						import('./views/app'),
				},
				{
					path: '/new',
					meta: {
						title: 'Add New Broadcast',
						sidebar: true,
						crumbName: 'New Broadcast',
						dataRequired: true
					},
					component: () =>
						import('./views/app/newBroadcast'),
				},
				{
					path: '/resend/:id',
					meta: {
						title: 'Resend Broadcast',
						sidebar: true,
						crumbName: 'Resend Broadcast',
						dataRequired: true
					},
					component: () =>
						import('./views/app/newBroadcast'),
				},
				{
					path: '/template',
					meta: {
						title: 'Add New Template',
						sidebar: true,
						crumbName: 'New Template',
						dataRequired: true
					},
					component: () =>
						import('./views/app/newTemplate'),
				},
				{
					path: '/draft/:id',
					meta: {
						title: 'Edit Broadcast',
						sidebar: true,
						dataRequired: true,
						edit: true
					},
					component: () =>
						import('./views/app/editBroadcast'),
				},
				{
					path: '/scheduled/:id',
					meta: {
						title: 'Edit Broadcast',
						sidebar: true,
						dataRequired: true,
						edit: true
					},
					component: () =>
						import('./views/app/editBroadcast'),
				},
				{
					path: '/template/:id',
					meta: {
						title: 'Edit Template',
						sidebar: true,
						dataRequired: true
					},
					component: () =>
						import('./views/app/editTemplate'),
				},
				{
					path: "/notifications",
					meta: {
						title: "All Notifications",
						sidebar: true,
						dataRequired: true
					},
					component: () => import('bh-mod/components/layout/TopBar/Actions/notifications.vue'),
				},
			],
		},
		{
			path: '/settings',
			redirect: '/settings',
			component: AppLayout,
			meta: { instanceRequired: true, auth: true, hidden: true, },
			children: [{
				path: '/settings',
				meta: {
					title: 'Email Settings',
					sidebar: true,
					dataRequired: true
				},
				component: () =>
					import('./views/app/settings'),
			}]
		},
		{
			path: '/setup',
			component: Splash,
			meta: { hidden: true, splash: true },
			children: [{
				path: '/setup',
				meta: {
					title: 'Setup Email & Broadcast',
					splashScreen: true
				},
				component: () =>
					import('./views/setup'),
			}]
		},
		{
			path: '/404',
			component: Splash,
			children: [
				{
					path: '/404',
					meta: {
						title: 'Error 404',
					},
					component: () => import('bh-mod/components/common/404.vue'),
				}
			]
		},
		{
			path: '/unauthorized',
			component: AuthLayout,
			children: [
				{
					path: '/unauthorized',
					meta: {
						title: 'Unauthorized',
						unauthorized: true
					},
					component: () => import('bh-mod/components/common/unauthorized.vue'),
				}
			]
		},
		// Other routes...
		{
			// Catch-all route: This will match any invalid route
			path: '*',
			redirect: '/404'
		}
	],
})

let hiveRequested = false;
router.beforeEach(async (to, from, next) => {
	let dataRequired = to.matched.some(record => record.meta.dataRequired)
	let splashScreen = to.matched.some(record => record.meta.splashScreen)
	let unauthorized = to.matched.some(record => record.meta.unauthorized)
	let error = false

	if (!hiveRequested) {
		let response = await VueAppMiddleware(to, store, 'email')
		error = response.parsed
		hiveRequested = true;
	}

	store.commit('PAGE_BUTTONS', [])

	if (error) {
		if (error === 'User does not have required permission!') {
			return next('/unauthorized')
		}
		return console.error('ERROR', error)
	} else {
		if (unauthorized) {
			return next();
		}
		if (splashScreen && (Object.keys(store.state.email.senders).length) && store.state.email.allSettings.userApp.options.seenIntro) {
			return next('/')
		} else if (dataRequired && !Object.keys(store.state.email.senders).length && !store.state.email.getStarted && !store.state.email.allSettings.userApp.options.seenIntro) {
			return next('/setup')
		}
	}

	if (to.fullPath === '/new') {
		let senders = store.state.email.senders;
		// let domain = store.state.email.domain;
		let sender = {};
		for (var i in senders) {
			if (senders[i].active) {
				sender = JSON.parse(JSON.stringify(senders[i]));
				break;
			}
		}
		// if (!sender.active) {
		// 	return next('/')
		// }
		// if (!domain.name) {
		// 	return next('/')
		// }
		// if (!domain.verified) {
		// 	return next('/')
		// }
	}

	next()
})
export default router
