import Vue from "vue";
import Vuex from "vuex";
import { setProp, deleteProp, arrToObj } from "bh-mod";
let tld = window.location.href.includes("bildhive.com") ? "com" : "dev";
let siteURL =
	window.location.href.includes("localhost") && sessionStorage.dev
		? "http://localhost:3001"
		: `https://services.bildhive.${tld}`;
siteURL = `https://services.bildhive.${tld}`;

Vue.use(Vuex);

export default {
	state: {
		editSectionObj: {
			show: false,
			item: {},
		},
		filter: "Sent",
		modelTags: {},
		tempEmail: {},
		chooseColor: {
			show: false,
			data: {},
		},
		sectionModal: {
			init: false,
			show: false,
			cb: null,
			restriction: "none",
			replace: false,
		},
		siteURL,
		cson: [],
		broadcastModal: {
			show: false,
			id: "",
		},
		emailVariable: {
			show: false,
			fields: [],
		},
		sections: {},
		editBroadcast: {},
		senders: [],
		domain: { name: "", verified: false, hasSSLCertificate: false },
		loadingPage: false,
		newStoreTime: Date.now(),
		getStarted: false,
		allSettings: {},
		templates: {},
		drafts: {},
		broadcasts: {},
		dom: { w: document.body.clientWidth, h: document.body.clientHeight },
		addContent: {
			show: false,
			page: {},
			isNew: false,
		},
		templateDrawer: {
			show: false,
			type: "add",
			site: "",
			data: {},
		},
		modal: {
			show: false,
			title: "",
		},
	},
	mutations: {
		STORE_MIDDLEWARE: (state, to) => {
			if (!to.query.edit) {
				state.templateDrawer = {
					show: false,
					type: "add",
					site: "",
					data: {},
				};
			}
		},
		SET_EMAIL_FILTER: (state, filter) => {
			if (filter) {
				state.filter = filter;
			}
			if (
				!["Processing", "Drafts", "Sent", "Scheduled", "Templates"].includes(
					state.filter
				)
			) {
				if (Object.values(state.broadcasts).find((x) => x.processing))
					state.filter = "Processing";
				else state.filter = "Drafts";
				return;
			}
		},
		UPDATE_TEMPLATES(state, data) {
			state.templates[data.id] = data;
		},
		EMAIL_TYPE(state, data) {
			state.filter = data.value;
		},
		SET_SECTION: (state, section) => (state.sections[section.id] = section),
		SET_SECTIONS: (state, sections) =>
			(state.sections = { ...state.sections, ...arrToObj(sections) }),
		EDIT_BROADCAST(state, data) {
			state.editBroadcast = data;
		},
		CHOOSE_COLOR: (state, data) => {
			state.chooseColor = {
				show: true,
				type: data.type,
				color: data.color,
			};
		},
		SET_EMAIL_VARIABLES: (state, data) => (state.emailVariable.fields = data),
		SHOW_EMAIL_VARIABLE: (state) => (state.emailVariable.show = true),
		CLOSE_EMAIL_VARIABLE: (state) => (state.emailVariable.show = false),
		SET_BROADCAST: (state, data) =>
			(state.broadcastModal = { show: true, data }),
		CLOSE_BROADCAST: (state, data) =>
			(state.broadcastModal = { show: false, data: {} }),
		VIEWPORT: (state, w) => (state.dom = w),
		SHOW_ADD_CONTENT: (state, { page, isNew }) =>
			(state.addContent = { show: true, page, isNew }),
		CLOSE_ADD_CONTENT: (state) =>
			(state.addContent = { show: false, page: {}, isNew: false }),
		SET_CSON: (state, cson) => (state.cson = cson),
		LOAD_PAGE: (state, status = !state.loadingPage) =>
			(state.loadingPage = status),
		SET_PROP: (state, { where, what, del = false, update = false }) => {
			if (del) return deleteProp(state, where);
			if (typeof where === "string") where = where.split(".");
			state.newStoreTime = Date.now();
			setProp(state, where, what);
		},
		CLOSE_DRAWERS: (state) => {
			state.templateDrawer = {
				show: false,
				type: "",
				id: "",
				data: null,
			};
			state.broadcastModal = { show: false, data: {} };
		},
		CLOSE_MODALS: (state) => {
			state.statusModal = { type: "", show: false };
			state.formModal = { show: false, type: "" };
			state.chooseColor = { show: false, type: "", color: "" };
		},
		SHOW_ADD_TEMPLATE: (state) => {
			state.templateDrawer = {
				show: true,
				type: "add",
				id: "",
				data: null,
			};
		},
		SHOW_EDIT_TEMPLATE: (state, data) => {
			state.templateDrawer = {
				show: true,
				type: "edit",
				id: "",
				data,
			};
		},
		SET_APPDATA(state, data) {
			if (!data) data = {};
			let {
				templates = [],
				broadcasts = [],
				senders = [],
				sendingDomains = [],
			} = data;
			let drafts = [];

			state.broadcasts = arrToObj(broadcasts);
			state.drafts = arrToObj(drafts);
			state.templates = arrToObj(templates);
			state.senders = arrToObj(senders);

			if (sendingDomains.length) {
				const parentInstanceData =
					sendingDomains.find((s) => s.inherited) || {};
				const childInstanceData =
					sendingDomains.find((s) => !s.inherited) || {};

				state.domain = {
					name: parentInstanceData.name || childInstanceData.name || "",
					verified:
						!!parentInstanceData.isVerified ||
						!!childInstanceData.isVerified ||
						false,
					hasSSLCertificate:
						!!parentInstanceData.hasSSLCertificate ||
						!!childInstanceData.hasSSLCertificate ||
						false,
				};
			}
		},
		SET_DOMAIN(state, data = {}) {
			state.domain = {
				name: data.name,
				verified: !!data.isVerified,
				hasSSLCertificate: !!data.hasSSLCertificate,
			};
		},
		GET_STARTED: (state) => {
			state.getStarted = true;
		},
		SET_SETTINGS(state, data) {
			let temp = data;

			if (data.userApp == null) {
				temp.userApp = {
					options: {
						seenIntro: false,
						email: [],
					},
				};
			}

			if (data.app == null) {
				temp.app = {
					options: {
						unsubscribeReasons: [],
						unsubscribePageDesign: {
							logo: '',
							primaryColor: '#0d6efd',
							primaryFont: 'Georgia',
						}
					},
				};
			}

			if (!data.app.options.unsubscribeReasons) {
				temp.app.options.unsubscribeReasons = [];
			}
			if (!data.app.options.unsubscribePageDesign) {
				temp.app.options.unsubscribePageDesign = {
					logo: '',
					primaryColor: '#0d6efd',
					primaryFont: 'Georgia',
				};
			}
			state.allSettings = temp;
		},
		UPDATE_ALLSETTINGS(state, data) {
			state.allSettings.userApp.options = data.options;
		},
		UPDATE_BROADCAST(state, broadcasts) {
			state.broadcasts = arrToObj(broadcasts);
		},
		UPDATE_UNSUBSCRIBE_REASONS(state, { action, reason, index }) {
			const list = state.allSettings.app.options.unsubscribeReasons;

			if (action === "add") {
				list.push(reason);
			} else if (action === "update") {
				list[index] = reason;
			} else if (action === "remove") {
				list.splice(index, 1);
			}
			Vue.set(state.allSettings.app.options, "unsubscribeReasons", list);
		},
		SET_OPTIONS: (state, { type, where, action = "", what, key }) => {
			if (!type) type = "app";
			if (!state.allSettings[type]) state.allSettings[type] = { options: {} }
			let settings = state.allSettings[type].options;
			what = JSON.parse(JSON.stringify(what));

			if (action.indexOf("array") === 0) {
				if (action === "arrayAdd") {
					if (!settings[where]) {
						settings[where] = [];
					}
					settings[where].push(what);
				} else if (action === "arrayEdit") {
					if (!key) key = "id";

					if (!settings.hasOwnProperty(where) || !what.hasOwnProperty(key))
						return;

					settings[where] = settings[where].map((x) =>
						x[key] === what[key] ? what : x
					);
				}
			} else if (!action) {
				if (!settings[where]) {
					settings[where] = [];
				}
				settings[where] = what;
			}
		}
	},
	actions: {
		SET_APPDATA: ({ commit }, data) => commit("SET_APPDATA", data),

		MESSAGE: ({ state }, data) => {
			document
				.querySelector("iframe")
				.contentWindow.postMessage(data, state.siteURL);
		},
	},
	getters: {
		completed: (state) => {
			return Object.values(state.broadcasts)
				.filter((x) => x.sent)
				.map((x) => ({ ...x, key: x.id }));
		},
		drafts: (state) => {
			return Object.values(state.broadcasts)
				.filter((x) => !x.sent && !x.isScheduled && !x.processing)
				.map((x) => ({ ...x, key: x.id }));
		},
		processing: (state) => {
			return Object.values(state.broadcasts)
				.filter((x) => !x.sent && (x.processing || x.error))
				.map((x) => ({ ...x, key: x.id }));
		},
		templates: (state) => {
			return Object.values(state.templates)
				.filter((x) => !x.default && x.instance)
				.map((x) => ({ ...x, key: x.id }));
		},
		scheduled: (state) => {
			return Object.values(state.broadcasts)
				.filter((x) => !x.sent && x.isScheduled)
				.map((x) => ({ ...x, key: x.id }));
		},
		emailApi: (state, getters, rootState) =>
			`/email-sections/${rootState.instance.id}`,
		fonts: () => [
			{
				name: "Arial",
				fontText: `Arial, sans-serif`,
			},
			{
				name: "Verdana",
				fontText: `Verdana, sans-serif`,
			},
			{
				name: "Georgia",
				fontText: "Georgia, serif",
			},
			{
				name: "Times New Roman",
				fontText: `'Times New Roman', serif`,
			},
			{
				name: "Courier",
				fontText: "Courier, sans-serif",
			},
			{
				name: "Abril Fatface",
				google: "Abril+Fatface",
				fontText: `'Abril Fatface', Georgia, serif`,
			},
			{
				name: "Alegreya",
				google: "Alegreya:wght@400;700",
				fontText: `'Alegreya', serif`,
			},
			{
				name: "Alegreya Sans",
				fontText: `'Alegreya Sans', sans-serif`,
				google: `Alegreya+Sans:wght@400;700`,
			},
			{
				name: "Alice",
				google: "Alice",
				fontText: "Alice,Helvetica, Arial, sans-serif",
			},
			{
				name: "Roboto",
				google: "Roboto:400,700",
				fontText: "Roboto, Helvetica, Arial, sans-serif",
			},
			{
				name: "Montserrat",
				google: "Montserrat:wght@400;700",
				fontText: "Montserrat, Helvetica, sans-serif",
			},
			{
				name: "Lato",
				google: "Lato:wght@400;700",
				fontText: `'Lato', sans-serif`,
			},
		],
	},
};
