<template>
    <div style="height: 100%">
        <LeftNavBar
            :title="selectedAppInitial"
            :drag="false"
            :hasChild="false"
            :items="sidebarItems"
            @opened="selectEmailType"
            :selectedValue="emailType"
        />
    </div>
</template>

<script>
import LeftNavBar from "bh-mod/components/common/LeftNavBar";
export default {
    components: {LeftNavBar },
    name: "menu-left",
    data() {
        return {
            openID: "",
        };
    },
    computed: {
		selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "BEB"
            );
        },
        sidebarItems() {
            let items = [
                {
                    label: "Drafts",
                    value: "Drafts",
                    iconUrl: require("@/assets/sideIcons/draft.svg"),
                    loading: false,
                    options: [],
                    children: [],
                },
                {
                    label: "Scheduled",
                    value: "Scheduled",
                    iconUrl: require("@/assets/sideIcons/scheduled.svg"),
                    loading: false,
                    options: [],
                    children: [],
                },
                {
                    label: "Processing",
                    value: "Processing",
                    iconUrl: require("@/assets/sideIcons/processing.svg"),
                    loading: false,
                    options: [],
                    children: [],
                },
                {
                    label: "Sent",
                    value: "Sent",
                    iconUrl: require("@/assets/sideIcons/sent.svg"),
                    loading: false,
                    options: [],
                    children: [],
                },
                {
                    label: "Templates",
                    value: "Templates",
                    iconUrl: require("@/assets/sideIcons/templates.svg"),
                    loading: false,
                    options: [],
                    children: [],
                },
                {
                    divider: true,
                },
                {
                    label: "Settings",
                    value: "Settings",
                    iconUrl: require("@/assets/sideIcons/settings.svg"),
                    loading: false,
                    options: [],
                    children: [],
                },
            ];
            return items;
        },
        forms() {
            return Object.values(this.$store.state.forms.allForms);
        },
        lots() {
            return this.$store.state.forms.lots;
        },
        folders() {
            return this.$store.getters.folders;
        },
        emailType() {
            return this.$store.state.email.filter;
        },
    },
    methods: {
        selectEmailType(data) {
            if (data.value && this.emailType != data.value) {
                if (data.value === "Settings" && this.$route.path !== "/settings") {
                    this.$router.push(`/settings`);
                    return this.$store.commit("EMAIL_TYPE", data);
                } else if (this.$route.path !== "/") {
                    this.$router.push(`/`);
                    return this.$store.commit("EMAIL_TYPE", data);
                } else {
                    this.$store.commit("EMAIL_TYPE", data);
                }
            }
            return;
        },
        handleClick(e) {
            this.$store.commit("changeFolder", e.key);
        },
    },
};
</script>
